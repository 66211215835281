import { StaticQuery } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import InstaLogo from "../../content/assets/instagram.png"

import "../utils/css/screen.css"

const ContactUsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Contact Us" />
      <article className="post-content page-template">
        <div className="post-content-body">
          <h2 style={{ textAlign: "left" }}>Contact Us</h2>
          <p>
            <a
              href="https://www.instagram.com/cherocaptures"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img width="40px" height="auto" src={InstaLogo} alt="Instagram" />
            </a>
          </p>
          <p>
            Phone: <a href="tel:+6285218419332">+62-852-1841-9332</a>{" "}
            (Nathaniel)
            <br />
            WhatsApp:{" "}
            <a
              href="https://wa.me/6285218419332?text=Hello,%20I%20would%20like%20to%20know%20more%20about%20CheroCaptures%20services."
              target="_blank"
              rel="noopener noreferrer"
            >
              Chat Now
            </a>
            <br />
            Email :{" "}
            <a href="mailto:hello@cherocaptures.com?cc=agustinusnathaniel228@gmail.com&subject=I would like to know more about CheroCaptures services">
              hello@cherocaptures.com
            </a>
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ContactUsPage location={props.location} data={data} {...props} />
    )}
  />
)
